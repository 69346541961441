import React from "react";

const Nft = () => {
  return (
    <section className="relative">
      <div className="text-center Chela-One text-top absolute 2xl:text-[80px] 2xl:pt-10 xl:text-[80px] xl:pt-8 md:pt-5 md:text-[40px] min-[375px]:text-[20px] min-[375px]:pt-2  text-white  w-full" >
      <p className=" ">DinoDao NFT</p>
    </div>
     
      <img
        className="w-full h-auto max-w-full"
        src="Group 22.svg"
        alt="background"
      />
    </section>
  );
};

export default Nft;
